import React, { useState } from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";

import {
  Title,
  Button,
  Section,
  Box,
  Text,
  InputGroup,
} from "../../components/Core";
import { device } from "../../utils";

const SectionStyled = styled(Section)`
  padding-bottom: 50px;
  padding-top: 0;
`;

const OfferWrapper = styled(Box)`
  box-shadow: ${({ theme }) => `0 20px 22px ${theme.colors.shadow}`};
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.light};
  padding-top: 30px;
  padding-bottom: 30px;
`;

const BtnContainer = styled(Box)`
  margin-left: 45px;
  margin-top: 30px;
  padding-right: 50px;
  width: 100%;

  @media ${device.lg} {
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    width: auto;
  }
`;

const BtnContainer2 = styled(Box)`
  width: 100%;
  margin-bottom: 10px;
  height: 60px;

  @media ${device.lg} {
    width: 400px;
    margin-bottom: 0px;
    margin-right: 10px;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
  // height: 60px;
  white-space: normal;
  @media ${device.lg} {
    width: auto;
  }
`;

const StyledInputGroup = styled(InputGroup)`
  background: #f7f7fb;
  padding: 1rem 1.75rem;
`;

const Spinner = styled.svg`
  animation: rotate 2s linear infinite;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -15px 0 0 -15px;
  width: 30px;
  height: 30px;

  & .path {
    stroke: #fff;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

class OfferContainer extends React.Component {
  state = {
    name: "",
    email: "",
    invalidEmail: false,
    submitting: false,
    submitSuccess: false,
    submitFail: false,
  };

  handleChange = (e) => {
    let toUpdate = {};
    toUpdate[e.target.name] = e.target.value;
    this.setState(toUpdate);
  };

  handleSubmit = () => {
    // eslint-disable-next-line
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(this.state.email)) {
      this.setState({ invalidEmail: false, submitting: true });
      fetch(
        "https://us-central1-tournamentsoftware-a1b3d.cloudfunctions.net/handleBrochureRequest",
        {
          mode: "cors",
          method: "POST",
          body: JSON.stringify({
            name: this.state.name,
            email: this.state.email,
            language: this.props.langKey || "en",
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      )
        .then(function (response) {
          return response.json();
        })
        .then(
          function (payload) {
            if (payload.status === "success") {
              this.setState({ submitting: false, submitSuccess: true });
            } else {
              this.setState({ submitting: false, submitFail: true });
            }
          }.bind(this)
        );
    } else {
      this.setState({ invalidEmail: true });
    }
  };

  render() {
    return (
      <Offer
        {...this.props}
        {...this.state}
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
      />
    );
  }
}

const Offer = (props) => {
  const [brochure, setBrochure] = useState(false);
  return (
    <>
      {/* <!-- Offer section 1 --> */}
      <SectionStyled bg={props.bg}>
        <Container>
          <OfferWrapper>
            <div className="d-flex flex-wrap flex-lg-nowrap">
              <div className="pl-5 pr-5">
                <Title variant="card" fontSize="24px" color="dark" mb={3}>
                  {props.subscription.name}
                </Title>
                <Text color="darkShade" fontSize="18px" lineHeight="28px">
                  {props.subscription.description1} <br />
                  {props.subscription.description2}
                </Text>
              </div>
              <BtnContainer>
                <StyledButton
                  bg="#f04037"
                  onClick={() => setBrochure(!brochure)}
                >
                  {props.subscription.request_brochure}
                </StyledButton>
              </BtnContainer>
            </div>

            {brochure && (
              <div
                className="d-flex flex-wrap align-items-center mt-4 pl-5 pr-5"
                style={{ borderTop: "1px solid #8090bb7a", paddingTop: 30 }}
              >
                {props.submitSuccess || props.submitFail ? (
                  props.submitSuccess ? (
                    <div style={{ color: "green", fontSize: 18 }}>
                      {props.subscription.request_thanks}
                    </div>
                  ) : (
                    <div style={{ color: "#f04037", fontSize: 18 }}>
                      {props.subscription.request_failed}
                    </div>
                  )
                ) : (
                  <>
                    <BtnContainer2>
                      <StyledInputGroup
                        placeholder={props.subscription.email}
                        className="text-center"
                        name="email"
                        value={props.email}
                        onChange={props.handleChange}
                        style={{ borderColor: props.invalidEmail ? "red" : "" }}
                      />
                    </BtnContainer2>
                    <BtnContainer2>
                      <StyledButton
                        width="100%"
                        bg="secondary"
                        onClick={props.handleSubmit}
                        disabled={props.submitting}
                        id="clickRequestBrochure"
                      >
                        {props.submitting ? (
                          <Spinner viewBox="0 0 50 50">
                            <circle
                              class="path"
                              cx="25"
                              cy="25"
                              r="20"
                              fill="none"
                              stroke-width="5"
                            ></circle>
                          </Spinner>
                        ) : (
                          props.subscription.request
                        )}
                      </StyledButton>
                    </BtnContainer2>
                  </>
                )}
              </div>
            )}
          </OfferWrapper>
        </Container>
      </SectionStyled>
    </>
  );
};

export default OfferContainer;
