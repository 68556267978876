import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { rgba } from "polished";

import { Title, Section, Box, Text, Button } from "../../components/Core";
import Offer from "./Offer";
import { device } from "../../utils";
import { defaultCurrency } from "../../../buildVariables";
import Ticker from "./Ticker";

const SectionStyled = styled(Section)`
  position: relative;
  ${
    "" /* &::after {
    content: "";
    left: 0;
    bottom: 0;
    height: 30%;
    width: 100%;
    position: absolute;
    background: ${({ theme }) => theme.colors.dark}!important;
  } */
  }
`;

const ULStyled = styled.ul`
  list-style: none;
  max-width: 80%;
  margin: 20px auto 0;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  li {
    color: #19191b;
    font-size: 21px;
    font-weight: 300;
    letter-spacing: -0.66px;
    // line-height: 50px;
    display: flex;
    margin-bottom: 20px;
    text-align: left;
    width: 100%;

    &:before {
      content: "\f00c";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      display: inline-block;
      font-size: 13px;
      min-width: 30px;
      height: 30px;
      background-color: ${({ theme }) => rgba(theme.colors.secondary, 0.1)};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 500px;
      color: ${({ theme }) => theme.colors.secondary};
      position: relative;
      // top: 9px;
      margin-right: 13px;
    }
  }
`;

const CardPricing = styled(Box)`
  box-shadow: ${({ theme }) =>
    `0 52px 54px ${rgba(theme.colors.shadow, 0.125)}`};
  border-radius: 10px;
  background-color: #fff;
  padding-top: 30px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  button {
    width: 100% !important;
    border-radius: 0 0 10px 10px !important;
  }
`;

const TitleSmall = styled.h4`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  // line-height: 28px;
`;

const Currency = styled.span`
  color: ${({ theme }) => theme.colors.dark};
  font-size: 30px;
  font-weight: 300;
  letter-spacing: -0.52px;
  line-height: 1;
  margin-bottom: 5px;
`;

const StyledTitle = styled(Title)`
  font-size: 55px;
  letter-spacing: -1.38px;
  margin-bottom: 0 !important;
  @media ${device.md} {
    font-size: 70px;
  }
`;

const ButtonGroup = styled.div`
  border-radius: 10px;
  padding: 5px;
  background-color: #ffffff;
  display: inline-flex;
  max-height: 65px;
  &.fullWidth {
    width: 100%;
    @media ${device.md} {
      width: auto;
    }
    @media ${device.lg} {
      width: 100% !important;
    }
  }
  &.withCurrency {
    width: auto;
  }
  .btn {
    height: 55px;
    min-width: 45px;
    display: inline-flex;
    justify-content: center;
    flex-grow: 1;
    align-items: center;
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.5px;
    color: #19191b;
    &.active {
      background-color: ${({ theme }) => theme.colors.secondary};
      color: #fff;
    }
    &:first-child {
      border-radius: 10px 0 0 10px;
    }
    &:last-child {
      border-radius: 0 10px 10px 0;
    }
    &:focus {
      box-shadow: none;
    }
  }
`;

const OnlineBarWrapper = styled.div`
  position: relative;
`;

const OnlineBarOpen = styled.div`
  position: absolute;
  background: #eee;
  padding: 5px;
  border-radius: 10px;
  top: 18px;
  margin: 10px;
  opacity: 0;
  transition: opacity 400ms ease;
  text-align: left;
  padding: 10px;
  z-index: 10;
  &:after {
    content: "";
    display: block;
    width: 15px;
    height: 15px;
    position: absolute;
    left: 15px;
    top: -7px;
    background-color: #eee;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

const OnlineBar = styled.div`
  position: absolute;
  background: #eee;
  padding: 5px 10px;
  border-radius: 10px;
  top: -30px;
  margin: 10px;
  &:hover {
    cursor: pointer;
    + .OnlineBarOpen {
      opacity: 1;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
    }
  }
`;

const DiscountBar = styled.div`
  position: absolute;
  background: #f04037;
  padding: 5px 10px;
  border-radius: 10px;
  top: -30px;
  right: 0;
  margin: 10px;
  color: white;
`;

class Pricing extends React.Component {
  state = {
    url: "",
  };

  componentDidMount() {
    this.setState({ url: window.location.hostname });
  }

  render() {
    const { url } = this.state;

    return <PricingElement {...this.props} url={url} />;
  }
}

const PricingElement = (props) => {
  const [online, setOnline] = useState(props.online || false);
  const [currency, setCurrency] = useState(defaultCurrency);
  const data = props.data.frontmatter.pricing;
  const prices = {
    EUR: { symbol: "€", worldclass: "40", legendary: "120", pro: "20" },
    USD: { symbol: "$", worldclass: "45", legendary: "135", pro: "23" },
    GBP: { symbol: "£", worldclass: "35", legendary: "100", pro: "18" },
    PLN: { symbol: "zł", worldclass: "180", legendary: "540", pro: "90" },
  };

  let discountPercentage = 0;

  if (
    (props.affiliate === "promo" || props.affiliate === "promo/") &&
    props.url.slice(-2) === "pl"
  ) {
    discountPercentage = 20;
  }

  return (
    <>
      {/* <!-- Pricing section --> */}
      <SectionStyled
        bg={props.dark ? "ash" : "#f7f7fb"}
        pt="90px !important"
        pb="40px !important"
      >
        <Container className="position-relative">
          <Row className="justify-content-md-center mb-4">
            <Col md="8" lg="9">
              <div className=" text-md-center">
                <Title color={props.dark ? "light" : "dark"}>
                  {data.heading || ""}
                </Title>
                <Text as="h4" color={props.dark ? "lightShade" : ""}>
                  {data.description || ""}
                </Text>
              </div>
            </Col>
          </Row>

          <Row
            className={
              props.multiCurrency ? "text-left" : "justify-content-center"
            }
          >
            <Col
              className={
                props.multiCurrency
                  ? "col-7 col-lg-5 col-xl-4"
                  : "col-12 col-lg-4 text-center"
              }
            >
              <ButtonGroup
                className={
                  props.multiCurrency ? "fullWidth withCurrency" : "fullWidth"
                }
              >
                <span
                  role="button"
                  className={`btn ${!online ? "active" : ""}`}
                  onClick={() => {
                    setOnline(false);
                  }}
                  onKeyDown={() => {
                    setOnline(false);
                  }}
                  tabIndex={0}
                >
                  <span
                    className={`${
                      props.multiCurrency ? "d-none d-md-block" : ""
                    } mr-2`}
                  >
                    {data.tournament}
                  </span>
                  <i className="fas fa-trophy" />
                </span>
                <span
                  role="button"
                  className={`btn ${online ? "active" : ""}`}
                  onClick={() => {
                    setOnline(true);
                  }}
                  onKeyDown={() => {
                    setOnline(true);
                  }}
                  tabIndex={0}
                >
                  <span
                    className={`${
                      props.multiCurrency ? "d-none d-md-block" : ""
                    } mr-2`}
                  >
                    {data.online}
                  </span>
                  <i className="fas fa-gamepad" />
                </span>
              </ButtonGroup>
            </Col>

            {props.multiCurrency && (
              <Col className="d-flex justify-content-end align-items-end col-5 col-lg-7 col-xl-8">
                <ButtonGroup>
                  <span
                    role="button"
                    className={`btn ${currency === "EUR" ? "active" : ""}`}
                    onClick={() => {
                      setCurrency("EUR");
                    }}
                    onKeyDown={() => {
                      setCurrency("EUR");
                    }}
                    tabIndex={0}
                  >
                    <i className="fas fa-euro-sign" />
                    <span className="d-none d-md-block ml-2">EUR</span>
                  </span>

                  <span
                    role="button"
                    className={`btn ${currency === "USD" ? "active" : ""}`}
                    onClick={() => {
                      setCurrency("USD");
                    }}
                    onKeyDown={() => {
                      setCurrency("USD");
                    }}
                    tabIndex={0}
                  >
                    <i className="fas fa-dollar-sign" />
                    <span className="d-none d-md-block ml-2">USD</span>
                  </span>

                  <span
                    role="button"
                    className={`btn ${currency === "GBP" ? "active" : ""}`}
                    onClick={() => {
                      setCurrency("GBP");
                    }}
                    onKeyDown={() => {
                      setCurrency("GBP");
                    }}
                    tabIndex={0}
                  >
                    <i className="fas fa-pound-sign" />
                    <span className="d-none d-md-block ml-2">GBP</span>
                  </span>
                </ButtonGroup>
              </Col>
            )}
          </Row>

          <Row className="text-center mt-4">
            {online ? (
              <>
                <Col lg="6" className="mb-4">
                  <CardPricing>
                    <OnlineBarWrapper>
                      <OnlineBar>
                        {data.online_tooltip}
                        <i className="fas fa-info-circle ml-1" />
                      </OnlineBar>
                      <OnlineBarOpen className="OnlineBarOpen">
                        {data.online_description}
                      </OnlineBarOpen>
                    </OnlineBarWrapper>
                    <div className="mb-4">
                      <TitleSmall>{data.onlinefree.name}</TitleSmall>
                      <div className="d-flex align-items-end justify-content-center mt-3 mb-2">
                        <StyledTitle>{data.free}</StyledTitle>
                        {/* <TimePer> /{data.tournament}</TimePer> */}
                      </div>
                      <Text fontSize="18px">{data.forever}</Text>
                      <ULStyled>
                        <li>{data.onlinefree.feature1}</li>
                        <li>{data.onlinefree.feature2}</li>
                        <li>{data.onlinefree.feature3}</li>
                      </ULStyled>
                    </div>
                    <a
                      href={
                        "https://" +
                        props.url +
                        "/manage/new" +
                        (props.affiliate ? "/?ref=" + props.affiliate : "")
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      id="StartNow"
                    >
                      <Button bg="secondary">{data.qta}</Button>
                    </a>
                  </CardPricing>
                </Col>
                <Col lg="6" className="mb-4">
                  <CardPricing>
                    <OnlineBarWrapper>
                      <OnlineBar>
                        {data.online_tooltip}
                        <i className="fas fa-info-circle ml-1" />
                      </OnlineBar>
                      <OnlineBarOpen className="OnlineBarOpen">
                        {data.online_description}
                      </OnlineBarOpen>
                      {discountPercentage > 0 && (
                        <DiscountBar
                          data-aos="zoom-out"
                          data-aos-duration="500"
                          data-aos-once="true"
                          data-aos-delay="500"
                        >
                          <i className="fas fa-gift mr-2" />
                          {"-" + discountPercentage + "%"}
                        </DiscountBar>
                      )}
                    </OnlineBarWrapper>

                    <div className="mb-4">
                      <TitleSmall>{data.pro.name}</TitleSmall>
                      <div className="d-flex align-items-end justify-content-center mt-3 mb-2">
                        {props.langKey !== "es" &&
                          props.langKey !== "fr" &&
                          props.langKey !== "de" &&
                          props.langKey !== "pl" && (
                            <Currency>{prices[currency].symbol}</Currency>
                          )}
                        <StyledTitle>
                          {discountPercentage > 0 ? (
                            <Ticker
                              className="count"
                              customStart={prices[currency].pro}
                              end={
                                prices[currency].pro *
                                ((100 - discountPercentage) / 100)
                              }
                            />
                          ) : (
                            prices[currency].pro
                          )}
                        </StyledTitle>
                        {(props.langKey === "es" ||
                          props.langKey === "fr" ||
                          props.langKey === "de" ||
                          props.langKey === "pl") && (
                          <Currency>{prices[currency].symbol}</Currency>
                        )}
                        {/* <TimePer> /{data.tournament}</TimePer> */}
                      </div>
                      <Text fontSize="18px">{data.pertournament}</Text>
                      <ULStyled>
                        <li>{data.pro.feature1}</li>
                        <li>{data.pro.feature2}</li>
                        <li>{data.pro.feature3}</li>
                      </ULStyled>
                    </div>
                    <a
                      href={
                        "https://" +
                        props.url +
                        "/manage/new" +
                        (props.affiliate ? "/?ref=" + props.affiliate : "")
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      id="StartNow"
                    >
                      <Button bg="secondary">{data.qta}</Button>
                    </a>
                  </CardPricing>
                </Col>
              </>
            ) : (
              <>
                <Col lg="4" className="mb-4">
                  <CardPricing>
                    <div className="mb-4">
                      <TitleSmall>{data.amateur.name}</TitleSmall>
                      <div className="d-flex align-items-end justify-content-center mt-3 mb-2">
                        <StyledTitle>{data.free}</StyledTitle>
                        {/* <TimePer> /{data.tournament}</TimePer> */}
                      </div>
                      <Text fontSize="18px">{data.forever}</Text>
                      <ULStyled>
                        <li>{data.amateur.feature1}</li>
                        <li>{data.amateur.feature2}</li>
                        <li>{data.amateur.feature3}</li>
                      </ULStyled>
                    </div>
                    <a
                      href={
                        "https://" +
                        props.url +
                        "/manage/new" +
                        (props.affiliate ? "/?ref=" + props.affiliate : "")
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      id="StartNow"
                    >
                      <Button bg="secondary">{data.qta}</Button>
                    </a>
                  </CardPricing>
                </Col>
                <Col lg="4" className="mb-4">
                  <CardPricing>
                    <OnlineBarWrapper>
                      {discountPercentage > 0 && (
                        <DiscountBar
                          data-aos="zoom-out"
                          data-aos-duration="500"
                          data-aos-once="true"
                          data-aos-delay="500"
                        >
                          <i className="fas fa-gift mr-2" />
                          {"-" + discountPercentage + "%"}
                        </DiscountBar>
                      )}
                    </OnlineBarWrapper>
                    <div className="mb-4">
                      <TitleSmall>{data.worldclass.name}</TitleSmall>
                      <div className="d-flex align-items-end justify-content-center mb-2 mt-3">
                        {props.langKey !== "es" &&
                          props.langKey !== "fr" &&
                          props.langKey !== "de" &&
                          props.langKey !== "pl" && (
                            <Currency>{prices[currency].symbol}</Currency>
                          )}
                        <StyledTitle>
                          {discountPercentage > 0 ? (
                            <Ticker
                              className="count"
                              customStart={prices[currency].worldclass}
                              end={
                                prices[currency].worldclass *
                                ((100 - discountPercentage) / 100)
                              }
                            />
                          ) : (
                            prices[currency].worldclass
                          )}
                        </StyledTitle>
                        {(props.langKey === "es" ||
                          props.langKey === "fr" ||
                          props.langKey === "de" ||
                          props.langKey === "pl") && (
                          <Currency>{prices[currency].symbol}</Currency>
                        )}
                      </div>
                      <Text fontSize="18px">{data.pertournament}</Text>
                      <ULStyled>
                        <li>{data.worldclass.feature1}</li>
                        <li>{data.worldclass.feature2}</li>
                        <li>{data.worldclass.feature3}</li>
                        <li>{data.worldclass.feature4}</li>
                      </ULStyled>
                    </div>
                    <a
                      href={
                        "https://" +
                        props.url +
                        "/manage/new" +
                        (props.affiliate ? "/?ref=" + props.affiliate : "")
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      id="StartNow"
                    >
                      <Button bg="secondary">{data.qta}</Button>
                    </a>
                  </CardPricing>
                </Col>
                <Col lg="4" className="mb-4">
                  <CardPricing>
                    <OnlineBarWrapper>
                      {discountPercentage > 0 && (
                        <DiscountBar
                          data-aos="zoom-out"
                          data-aos-duration="500"
                          data-aos-once="true"
                          data-aos-delay="500"
                        >
                          <i className="fas fa-gift mr-2" />
                          {"-" + discountPercentage + "%"}
                        </DiscountBar>
                      )}
                    </OnlineBarWrapper>

                    <div className="mb-4">
                      <TitleSmall>{data.legendary.name}</TitleSmall>
                      <div className="d-flex align-items-end justify-content-center mt-3 mb-2">
                        {props.langKey !== "es" &&
                          props.langKey !== "fr" &&
                          props.langKey !== "de" &&
                          props.langKey !== "pl" && (
                            <Currency>{prices[currency].symbol}</Currency>
                          )}
                        <StyledTitle>
                          {discountPercentage > 0 ? (
                            <Ticker
                              className="count"
                              customStart={prices[currency].legendary}
                              end={
                                prices[currency].legendary *
                                ((100 - discountPercentage) / 100)
                              }
                            />
                          ) : (
                            prices[currency].legendary
                          )}
                        </StyledTitle>
                        {(props.langKey === "es" ||
                          props.langKey === "fr" ||
                          props.langKey === "de" ||
                          props.langKey === "pl") && (
                          <Currency>{prices[currency].symbol}</Currency>
                        )}
                        {/* <TimePer> /{data.tournament}</TimePer> */}
                      </div>
                      <Text fontSize="18px">{data.pertournament}</Text>
                      <ULStyled>
                        <li>{data.legendary.feature1}</li>
                        <li>{data.legendary.feature2}</li>
                        <li>{data.legendary.feature3}</li>
                        <li>{data.legendary.feature4}</li>
                      </ULStyled>
                    </div>
                    <a
                      href={
                        "https://" +
                        props.url +
                        "/manage/new" +
                        (props.affiliate ? "/?ref=" + props.affiliate : "")
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      id="StartNow"
                    >
                      <Button bg="secondary">{data.qta}</Button>
                    </a>
                  </CardPricing>
                </Col>
              </>
            )}
          </Row>
        </Container>
      </SectionStyled>
      <Offer
        bg={props.dark ? "ash" : "#f7f7fb"}
        subscription={data.subscription}
        langKey={props.langKey}
      />
    </>
  );
};

export default Pricing;
