import React from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";

import { Box, Text } from "../../components/Core";
import { device } from "../../utils";
import PreviewCompatibleImage from "../../components/PreviewCompatibleImage";

const TitleContainer = styled(Box)`
  position: relative;
  &:after {
    content: "";
    height: 1px;
    position: absolute;
    right: 0;
    top: 50%;
    width: 100%;
    background: ${({ theme }) => theme.colors.border};
    margin-top: 0.5px;
    display: none;

    @media ${device.md} {
      width: 5%;
      display: block;
    }
    @media ${device.lg} {
      width: 30%;
    }
    @media ${device.xl} {
      width: 40%;
    }
  }
`;

const Brand = styled(Box)`
  opacity: 1;
  min-width: 70px;
  margin-left: 20px;
  margin-right: 20px;
  transition: all 0.3s ease-out;
  &:hover {
    opacity: 0.7;
  }
`;

const Clients = (props) => (
  <>
    {/* <!-- Clients Brands Area --> */}
    <Box
      pb={props.pb}
      pt={props.pt}
      bg={props.bg}
      data-aos={props.aos ? "fade-in" : ""}
      data-aos-duration="750"
      data-aos-delay="500"
      data-aos-once="true"
      style={{ zIndex: 10 }}
    >
      <Container>
        <TitleContainer mb={"20px"}>
          <Text fontSize="18px" as="h4" className="">
            {props.heading}{" "}
          </Text>
        </TitleContainer>
      </Container>
      <Container>
        <Box
          className="d-flex justify-content-center justify-content-lg-between
         align-items-center flex-wrap"
          mx="-20px"
        >
          {props.clients.map((client, i) => (
            <Brand key={i} className="" py={3}>
              <PreviewCompatibleImage
                imageInfo={{
                  image: client.client,
                }}
              />
            </Brand>
          ))}
        </Box>
      </Container>
    </Box>
  </>
);

export default Clients;
